import './SuperCity.css'

import image1 from '../../img/SUPERCITY/1_1-min.jpg'
import image2 from '../../img/SUPERCITY/2_2-min.jpg'
import image3 from '../../img/SUPERCITY/3_3-min.jpg'
import image4 from '../../img/SUPERCITY/4_4-min.jpg'
import image5 from '../../img/SUPERCITY/5_5-min.jpg'
import image6 from '../../img/SUPERCITY/6_6-min.jpg'
import image7 from '../../img/SUPERCITY/7_7-min.jpg'
import image8 from '../../img/SUPERCITY/8_8-min.jpg'
import image9 from '../../img/SUPERCITY/9_9-min.jpg'
import image10 from '../../img/SUPERCITY/10_10-min.jpg'


import img1 from '../../img/SUPERCITY/супер сити_сетка_1-min.jpg';
import img2 from '../../img/SUPERCITY/супер сити_сетка_2-min.jpg';
import img3 from '../../img/SUPERCITY/супер сити_сетка_3-min.jpg';
import img4 from '../../img/SUPERCITY/супер сити_сетка_4-min.jpg';
import aboutSuperCity from '../../img/portfolio-about-small/super-city.png'
const superCityTitle = 'SUPER CITY ROBLOX'
const superCitySubtitle = 'Игра в Roblox собрала более 2 млн игроков. Интегрировали партнеров в локацию: «Мегафон» и «Московский транспорт». Приблизили будущее. Вот оно - залетайте.'


export default function SuperCity(){
    return(
        <div className='SuperCity'>
            <div className="section project__about">
                <div className="project__about-container">
                    <p className="project__text project__about-title">{superCityTitle}</p>
                    <p className="project__text project__about-text">{superCitySubtitle}</p>
                </div>
                <img className="project__about-image" src={aboutSuperCity} loading="lazy"/>
            </div>
            <div>
                <img className='SuperCity__main-img' src={image1}></img>
                <img className='SuperCity__main-img' src={image2}></img>
                <img className='SuperCity__main-img' src={image3}></img>
                <img className='SuperCity__main-img' src={image4}></img>
                <img className='SuperCity__main-img' src={image5}></img>
                <img className='SuperCity__main-img' src={image6}></img>
                <img className='SuperCity__main-img' src={image7}></img>
                <img className='SuperCity__main-img' src={image8}></img>
                <img className='SuperCity__main-img' src={image9}></img>
                <img className='SuperCity__main-img' src={image10}></img>
                <div className='SuperCity__images-1'>
                    <img className='SuperCity__imgOf4 div1' src={img1}></img>
                    <img className='SuperCity__imgOf4 div2' src={img2}></img>
                    <img className='SuperCity__imgOf4 div3' src={img3}></img>
                    <img className='SuperCity__imgOf4 div4' src={img4}></img>
                </div>
                <iframe className='SuperCity__video' src="https://www.youtube.com/embed/-tiwfA6hyD4?si=cL24iKIvqk9K0lJ9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    )
}