import React, { useState, useRef, useEffect } from 'react';
import './VideoButton.css'; 
import videoSrc from '../../videos/servicesVideo.mp4'; 
import closeButtonImage from '../../img/close_button.png';
import icon from '../../img/playbuttonsmall.png';

const VideoButton = () => {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const videoRef = useRef(null);

  const handleButtonClick = () => {
    setVideoVisible(true);
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setIsClosing(true);
  };

  useEffect(() => {
    if (isVideoVisible && videoRef.current) {
      videoRef.current.play();
    }
  }, [isVideoVisible]);

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        setIsClosing(false);
        setVideoVisible(false);
      }, 300); 
      return () => clearTimeout(timer);
    }
  }, [isClosing]);

  return (
    <div className="video-container">
      <button className="video-button" onClick={handleButtonClick}>ВСЕ УСЛУГИ
        <img src={icon} alt="icon" className="button-icon" />
      </button>
      <div className={`video-overlay ${isVideoVisible ? 'visible' : ''} ${isClosing ? 'closing' : ''}`}>
        <div className={`video-content ${isVideoVisible ? 'scale-up' : ''} ${isClosing ? 'scale-down' : ''}`}>
          <div className="video-wrapper">
            <video
              ref={videoRef}
              src={videoSrc}
              muted
              loop
              className="video-element"
            />
          </div>
          <button className="close-button" onClick={handleCloseClick}>
            <img src={closeButtonImage} alt="Close" className="close-button-image" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoButton;