import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import PortfolioPage from './PortfolioPage';
import Project from './blocks/Project/Project';
import ServicesExpand from '../src/blocks/ServicesExpand/ServicesExpand';
import { useEffect } from 'react';
import Privacy from './blocks/Privacy/Privacy';

function App() {
  useEffect(() => {
    function removeElementsByClass(root, className) {
      const elements = root.querySelectorAll('.' + className);
      elements.forEach(element => {
        element.remove();
      });
    }

    removeElementsByClass(document, 'media-controls-container');

    const possibleShadowHosts = document.querySelectorAll('*');
    possibleShadowHosts.forEach(host => {
      if (host.shadowRoot) {
        removeElementsByClass(host.shadowRoot, 'media-controls-container');
      }
    });
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/portfolio/:area" element={<Project />} />

          <Route path="/services/add" element={<ServicesExpand />} />
          <Route path="/services/motion" element={<ServicesExpand />} />
          <Route path="/services/avatars" element={<ServicesExpand />} />
          <Route path="/services/metaverse" element={<ServicesExpand />} />
          <Route path="/services/ar-xr-vr" element={<ServicesExpand />} />
          <Route path="/services/ai" element={<ServicesExpand />} />
          
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;