import { useNavigate } from 'react-router-dom';
import './UpButton.css'

export default function UpButton(){
    const navigate = useNavigate();

    const handleClick = () => {
        const path = "#header";
        console.log(window.location.pathname)
        // navigate(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return(
        <div className='upButton' onClick={handleClick}>
            <svg width="62" height="80" viewBox="0 0 62 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="60" height="78" rx="29" stroke="white" stroke-width="2"/>
                <path d="M30 56C30 56.5523 30.4477 57 31 57C31.5523 57 32 56.5523 32 56L30 56ZM31.7071 25.2929C31.3166 24.9024 30.6834 24.9024 30.2929 25.2929L23.9289 31.6569C23.5384 32.0474 23.5384 32.6805 23.9289 33.0711C24.3195 33.4616 24.9526 33.4616 25.3431 33.0711L31 27.4142L36.6569 33.0711C37.0474 33.4616 37.6805 33.4616 38.0711 33.0711C38.4616 32.6805 38.4616 32.0474 38.0711 31.6569L31.7071 25.2929ZM32 56L32 26L30 26L30 56L32 56Z" fill="white"/>
            </svg>
        </div>
    )
}
