import React, { useState, useEffect } from 'react';
import './UslugiButton.css'

const UslugiButton = () => {
    return (
      <div className="button-container">
        <li className="uslugi_button"> <a className='header__link text-medium' href='#services'>ВСЕ УСЛУГИ</a></li>
      </div>
    );
};
  
export default UslugiButton;
