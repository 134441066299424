import './Steps.css';

const steps = [
  'Обсуждение идеи и сбор всех материалов',
  'Расчет стоимости и составление договора',
  'Разработка проекта и согласование с заказчиком',
  'Интеграция, настройка и контроль проекта',
  'Закрытие проекта. Обнимашки с заказчиком',
]

export default function Steps(){
    return (
        <div className="steps">
            <div className='steps__container'>
                <p className='text-big steps__title'>Этапы работы с заказчиком</p>
                <div className='steps__items'>
                  {
                    steps.map((item, index) => {
                      return (
                        <div key={index} className='steps__item'>
                          <p className='steps__number'>{index + 1}</p>  
                          <p className='steps__step'>{item}</p>  
                        </div>
                      )
                    })
                  }
                </div>
            </div>
        </div>
    )
}