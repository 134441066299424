import React from "react";
import PropTypes from "prop-types";

const Collapsible = ({ title, pos, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(() => {
    if (pos === 1) {
      return true;
    }
    return false;
  });

  const ref = React.useRef();

  const [height, setHeight] = React.useState();

  const handleToggle = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
    setHeight(ref.current.clientHeight);
  };

  const classes = `list-group-item  ${isExpanded ? "is-expanded" : null}`;
  const currentHeight = isExpanded ? height : 0;
  return (
    <div
      className={classes}
      style={{
        border: "none",
      }}
      onClickCapture={handleToggle}
    >
      <div className={`card-title`}>
        <p className={`${isExpanded ? "card-title_colored" : ""} text-big card__title-animated`}>{title}</p>
      </div>
      <div className="card-collapse" style={{ height: currentHeight + "px" }}>
        <div className="" ref={ref}>
          {children}
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string,
};

export default Collapsible;
