import './IRIPage.css'
import mainImg from '../../img/IRI/1_iri-min.jpg'
import mainImg2 from '../../img/IRI/2_iri-min.jpg'
import mainImg3 from '../../img/IRI/3_iri-min.jpg'

import video1 from '../../img/IRI/webm/гжель и хохлома.webm'
import video1_mp4 from '../../img/IRI/h264/гжель и хохлома.mp4'

import video2 from '../../img/IRI/webm/лед.webm'
import video2_mp4 from '../../img/IRI/h264/лед.mp4'

import video3 from '../../img/IRI/webm/картины.webm'
import video3_mp4 from '../../img/IRI/h264/картины.mp4'

import video4 from '../../img/IRI/webm/нейронка.webm'
import video4_mp4 from '../../img/IRI/h264/нейронка.mp4'

import video5 from '../../img/IRI/webm/ири луп.webm'
import video5_mp4 from '../../img/IRI/h264/ири луп.mp4'

import video6 from '../../img/IRI/webm/этника.webm'
import video6_mp4 from '../../img/IRI/h264/этника.mp4'

import video7 from '../../img/IRI/webm/балоны.webm'
import video7_mp4 from '../../img/IRI/h264/балоны.mp4'

import img1 from '../../img/IRI/4_1_setka-min.jpg';
import img2 from '../../img/IRI/4_2_setka-min.jpg';
import img3 from '../../img/IRI/4_3_setka-min.jpg';
import img4 from '../../img/IRI/4_4_setka-min.jpg';
import aboutIri from '../../img/portfolio-about-small/iri.png'

const iriTitle = 'ВФМ 2024. Проект ИРИ «Digital Берёзы»'
const iriSubtitle = 'Медиаинсталляция в Олимпийском парке Сочи «Сириус».Разработка дизайна, застройка, техническое сопровождение от Brothers Production. 5 тематических дней, 3 концерта, более 100 позиций созданного  контента. Все это под присмотром тысяч студентов из разных стран на Всемирном Фестивале Молодежи.'


export default function IRIPage(){
    return(
        <div className='iriPage'>
            <div className="section project__about">
                <div className="project__about-container">
                    <p className="project__text project__about-title">{iriTitle}</p>
                    <p className="project__text project__about-text">{iriSubtitle}</p>
                </div>
                <img className="project__about-image" src={aboutIri} loading="lazy"/>
            </div>
            <div style={{backgroundColor: 'black'}}>
                <img className='iriPage__full-img' src={mainImg}></img>
                <img className='iriPage__full-img' src={mainImg2}></img>
                
                <iframe className='iriPage__video'
                    src="https://rutube.ru/play/embed/814fb129a2b8bca3c18c7feef9f3b9f3"
                    title="RuTube video player"
                    frameBorder="0"
                    allow="clipboard-write; autoplay"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                ></iframe>

                <p className='iriPage__about'>STAGE DESIGN</p>
                <img className='iriPage__full-img' src={mainImg3}></img>
                <div className='iriPage__images-1'>
                    <img className='iriPage__imgOf4 div1' src={img1}></img>
                    <img className='iriPage__imgOf4 div2' src={img2}></img>
                    <img className='iriPage__imgOf4 div3' src={img3}></img>
                    <img className='iriPage__imgOf4 div4' src={img4}></img>
                </div>
                <p className='iriPage__about'>DIGITAL БЕРЁЗЫ</p>     

                <iframe className='iriPage__video'
                    src="https://rutube.ru/play/embed/1706f20f1db79e1d36460c2f4fd19110/?p=m4x40G-stv_cPMdc6vc3zQ"
                    frameBorder="0"
                    allow="clipboard-write; autoplay"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                ></iframe>
                
                <div>
                    <div className='iriPage__content'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>этнические узоры</p>
                            <p className='iriPage__subtitle'>Интерпретация классических орнаментов. Трансформация в объемные формы и новый взгляд на исконные традиции.</p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video1} type='video/webm'></source>
                            <source src={video1_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
                <div>
                    <div className='iriPage__content iriPage__content_reverse'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>русский дух</p>
                            <p className='iriPage__subtitle'>Отображение гесграфических особенностей, уникальных черт региона, погодных условий и внутреннего мира через контент.</p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video2} type='video/webm'></source>
                            <source src={video2_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
                <div>
                    <div className='iriPage__content'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>ИСКУССТВО</p>
                            <p className='iriPage__subtitle'>Современная трактовка и компьютерное видение художественной классики.Ожившие картины великих творцов.</p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video3} type='video/webm'></source>
                            <source src={video3_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
                <div>
                    <div className='iriPage__content iriPage__content_reverse'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>нейронные сети</p>
                            <p className='iriPage__subtitle'>Контент с использованием последних технологий, современного программного обеспечения, рендеринга и материалов.</p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video4} type='video/webm'></source>
                            <source src={video4_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
                <div>
                    <div className='iriPage__content'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>СПОНСОРЫ</p>
                            <p className='iriPage__subtitle'>Отдельный блок контента был посвящен организаторам. Рекламные интеграции и инфографика в соответствии с брендбуком</p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video5} type='video/webm'></source>
                            <source src={video5_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
                <div>
                    <div className='iriPage__content iriPage__content_reverse'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>МЕЖДУНАРОДНЫЕ ОРНАМЕНТЫ</p>
                            <p className='iriPage__subtitle'>В рамках международного фестиваля использовались узоры и орнаменты разных стран</p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video6} type='video/webm'></source>
                            <source src={video6_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
                <div>
                    <div className='iriPage__content'>
                        <div className='iriPage__content-container'>
                            <p className='iriPage__title'>абстракция</p>
                            <p className='iriPage__subtitle'>Развлекательный контент для тематических дней и привлечения внимания
    </p>
                        </div>
                        <video
                            className='iriPage__content-video'
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={video7} type='video/webm'></source>
                            <source src={video7_mp4} type='video/mp4'></source>
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}