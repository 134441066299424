import './AlphaPage.css'

import image1 from '../../img/ALPHA/1_alfa-min.jpg'
import image2 from '../../img/ALPHA/2_alfa-min.jpg'

import image4 from '../../img/ALPHA/4_alfa-min.jpg'
import image5 from '../../img/ALPHA/5_alfa-min.jpg'
import image6 from '../../img/ALPHA/6_alfa-min.jpg'
import image7 from '../../img/ALPHA/7_alfa-min.jpg'
import image8 from '../../img/ALPHA/8_alfa-min.jpg'
import image9 from '../../img/ALPHA/9_alfa-min.jpg'
import image10 from '../../img/ALPHA/10_alfa-min.jpg'

import aboutAlpha from '../../img/portfolio-about-small/alpha.png'

const alphaTitle = 'ALFA Digital. Онлайн конференция Alfa Bank'
const alphaSubtitle = 'Видели одежду эфира от которой все ахнули? Скорее всего нет, мероприятие было внутренним, для сотрудников компании. Им рассказали о топовых продуктах банка.А мы показали это и доказали, с помощью множества графики, видеороликов и инфографики. Заставки, титры, оформление выступлений спикеров, интерактивная графика, презентационные ролики'


export default function AlphaPage(){
    return(
        <div className='AlphaPage'>
            <div className="section project__about">
                <div className="project__about-container">
                    <p className="project__text project__about-title">{alphaTitle}</p>
                    <p className="project__text project__about-text">{alphaSubtitle}</p>
                </div>
                <img className="project__about-image" src={aboutAlpha} loading="lazy"/>
            </div>
            <div style={{backgroundColor: 'black'}}>
                <img className='AlphaPage__main-img' src={image1}></img>
                <img className='AlphaPage__main-img' src={image2}></img>

                <iframe className='AlphaPage__video' src="https://rutube.ru/play/embed/2ed55d1d037ac14ec830b3c0443133af"
                frameBorder="0"
                allow="clipboard-write; autoplay"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
                <img className='AlphaPage__main-img' src={image4}></img>
                <img className='AlphaPage__main-img' src={image5}></img>
                <img className='AlphaPage__main-img' src={image6}></img>
                <img className='AlphaPage__main-img' src={image7}></img>
                <img className='AlphaPage__main-img' src={image8}></img>
                <img className='AlphaPage__main-img' src={image9}></img>
                <img className='AlphaPage__main-img' src={image10}></img>
            </div>
        </div>
    )
}