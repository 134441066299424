import './Services.css';
import circle from '../../img/circle.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


function Services() {
  const navigate = useNavigate();

  useEffect(() => {
    const root = document.documentElement;
    const cursor = document.getElementById('custom-cursor');

    const handleMouseMove = (event) => {
      root.style.setProperty('--cursor-x', `${event.clientX}px`);
      root.style.setProperty('--cursor-y', `${event.clientY}px`);
    };

    const handleMouseEnter = (event) => {
      root.style.setProperty('--cursor-x', `${event.clientX}px`);
      root.style.setProperty('--cursor-y', `${event.clientY}px`);
      cursor.style.transform = 'translate(-50%, -50%) scale(1)';
    };

    const handleMouseLeave = () => {
      cursor.style.transform = 'translate(-50%, -50%) scale(0)';
    };

    window.addEventListener('mousemove', handleMouseMove);

    document.querySelectorAll('.services__item').forEach(item => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);

      document.querySelectorAll('.services__item').forEach(item => {
        item.removeEventListener('mouseenter', handleMouseEnter);
        item.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  const handleServiceRedirect = (area) => {
    switch (area) {
      case 'РЕКЛАМА':
        navigate(`/services/add`);
        break;
      case 'MOTION':
        navigate(`/services/motion`);
        break;
      case 'AVATAR':
        navigate(`/services/avatars`);
        break;
      case 'METAVERSE':
        navigate(`/services/metaverse`);
        break;
      case 'AR/VR/XR':
        navigate(`/services/ar-xr-vr`);
        break;
      case 'НЕЙРОСЕТИ':
        navigate(`/services/ai`);
        break;
      default:
        console.log('Unknown service area:', area);
    }
  };


  return (
    <div id='services' className="services">
        <img className='services__circle-decor' alt='circle' src={circle} loading="lazy"/>
        <p className='services__title text-big'>МЫ СОЗДАЕМ</p>
        <div className='services__container'>
            <div onClick={() => handleServiceRedirect('РЕКЛАМА')} className='services__item services__item1'><p className='services__item-title'>РЕКЛАМА</p>
              <div className='services__item-overlay'>
              <img className='services__item-background' alt='advertising' src='ServicesAssets/Services_background.png' />
                <ul className='services__list'>
                  {add.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('MOTION')} className='services__item services__item2'><p className='services__item-title'>MOTION</p>
              <div className='services__item-overlay'>
              <img className='services__item-background' alt='advertising' src='ServicesAssets/Services_background.png' />
                <ul className='services__list'>
                  {motion.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('AVATAR')} className='services__item services__item3'><p className='services__item-title'>АВАТАРЫ</p>
            <div className='services__item-overlay'>
            <img className='services__item-background' alt='advertising' src='ServicesAssets/Services_background.png' />
                <ul className='services__list'>
                {avatars.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('METAVERSE')} className='services__item services__item4'><p className='services__item-title'>METAVERSE</p>
              <div className='services__item-overlay'>
              <img className='services__item-background' alt='advertising' src='ServicesAssets/Services_background.png' />
                <ul className='services__list'>
                {metaverse.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('AR/VR/XR')} className='services__item services__item5'><p className='services__item-title'>AR/VR/XR</p>
              <div className='services__item-overlay'>
              <img className='services__item-background' alt='advertising' src='ServicesAssets/Services_background.png' />
                <ul className='services__list'>
                {ar_xr_vr.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
            <div onClick={() => handleServiceRedirect('НЕЙРОСЕТИ')} className='services__item services__item6'><p className='services__item-title'>НЕЙРОСЕТИ</p>
              <div className='services__item-overlay'>
              <img className='services__item-background' alt='advertising' src='ServicesAssets/Services_background.png' />
                <ul className='services__list'>
                {ai.map((item, index) => <li key={index} className='services__list-item'>{item}</li>)}
                </ul>
              </div>
            </div>
        </div>
        <div id="custom-cursor"> 
        </div>
      </div>
    );
  }
  
  export default Services;
  
  const add = [
    "Создание графики",
    "Тизеры и трейлеры",
    "VFX контент для соцсетей",
    "Разработка концепции"
  ];

  const motion = [
    "Эфирная графика для ТВ",
    "Медиадизайн для инсталляций",
    "Контент для концертов и event",
    "Интерактивный контент для expo",
  ]

  const avatars = [
    "Digital ведущие",
    "Маскоты",
    "Игровые персонажи",
    "Виртуальные инфлюенсеры",
  ]

  const metaverse = [
    "Roblox",
    "webGL",
    "Decentraland",
    "Spatial",
  ]


  const ar_xr_vr = [
    "Разработка приложений и web",
    "Интерактивный AR и VR",
    "Контент для XR студий",
    "Разработка ПО для трекинга",
  ]



  const ai = [
    "Чат-боты на основе ИИ",
    "Генерация ИИ видео",
    "ПО с нейронными сетями",
    "создание анимаций через ИИ",
  ]
