import Header from './blocks/Header/Header';
import Main from './blocks/Main/Main';
import Text from './blocks/Text/Text';
import Services from './blocks/Services/Services';
import ContactForm from './blocks/ContactForm/ContactForm';
import Footer from './blocks/Footer/Footer';
import Recomend from './blocks/Recomend/Recomend';
import { CyberSecsation } from './blocks/CyberSensation/CyberSensation';
// import Model from './Model';
// import UpButton from './blocks/UpButton/UpButton';
// import ThreeBackground from './blocks/ThreeBackground/ThreeBackground';
import Spline from '@splinetool/react-spline';
import { useEffect, useState } from 'react';
import loadingCircle from './img/loading-circle.png'
import VideoButton from './blocks/VideoButton/VideoButton';


function MainPage() {
  const [isLoading, setIsLoading] = useState(false);
  const handleLoad = () => {
    setIsLoading(false);
  };
  return (
    <>
    <div className="main-page">
      {/* <ThreeBackground/> */}
      <div id="bg-wrap" style={{position: 'fixed', width: '100%', height: '100%', zIndex: -1}}>
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
        <defs>
        <radialGradient id="Gradient1" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="40s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(160, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(160, 255, 0, 0)"></stop></radialGradient>
        <radialGradient id="Gradient3" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="40s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(160, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(160, 255, 0, 0)"></stop></radialGradient>
        <radialGradient id="Gradient2" cx="50%" cy="50%" fx="10%" fy="50%" r=".5"><animate attributeName="fx" dur="30.5s" values="0%;50%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(52, 40, 100, 1)"></stop><stop offset="100%" stop-color="rgba(52, 40, 100, 0)"></stop></radialGradient>
        </defs>
        <rect x="13.744%" y="1.18473%" width="30%" height="30%" fill="url(#Gradient1)" transform="rotate(0 50 50)"><animate attributeName="x" dur="100s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="100s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="100s" repeatCount="indefinite"></animateTransform></rect>
        <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(0 50 50)"><animate attributeName="x" dur="150s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="150s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="150s" repeatCount="indefinite"></animateTransform>
        </rect>
        </svg>
      </div>
      
      {/*
      <div   style={{position: 'fixed', width: '100%', height: '100%', zIndex: `${isLoading ? '100' : '-1'}`}}>
        {isLoading && <div className="preloader" style={{position: 'fixed', width: '100%', height: '100vh', backgroundColor: 'black'}}><div className='main-page__preloader-content'><img className='main-page__loadingCircle' src={loadingCircle}></img><p>Загрузка...</p></div></div>}
        <Spline scene="https://prod.spline.design/qyEErx3YqOv0-4iP/scene.splinecode" onLoad={handleLoad} />
      </div> */}

      {/* <div style={{position: 'fixed', width: '100%', height: '100%', zIndex: -1}}>
        <Spline scene="https://prod.spline.design/qyEErx3YqOv0-4iP/scene.splinecode" />
      </div> */}
      {/* <Model/> */}
      <Header title={'MAGNUM'}/>
      <Main/>
      <Services/>
      <VideoButton/>
      <Text/>
      <Recomend/>
      <CyberSecsation/>
      <div className='main-page__desktop-only'>
        <Spline scene="SplineModels/game.splinecode"></Spline>
      </div>
      <ContactForm/>
      <Footer/>
    </div>
    </>
  );
}

export default MainPage;
